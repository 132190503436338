<template>
    <div>
        <div v-if="selectedAccount.account || selectedAccount.account === 0">
            <div class="flex justify-between items-center border-b pb-3 mb-3">
                <p v-if="selectedAccount.oldAccount !== undefined">{{ selectedAccount.oldAccount }} <i class="fal fa-arrow-right mx-1" /> {{ selectedAccount.account }} {{ selectedAccount.name }}</p>
                <p v-else>{{ selectedAccount.account }} {{ selectedAccount.name }}</p>
                <div>
                    <el-button type="primary" @click="resetState" :disabled="!selectedAccount.oldAccount">Återställa</el-button>
                    <el-button type="primary" @click="editItem">Redigera</el-button>
                </div>
            </div>
            <table class="w-full text-sm">
                <tr class="border-b">
                    <th width="100" class="font-medium pb-1">Datum</th>
                    <th width="80" class="font-medium">Verifikat</th>
                    <th width="300" class="font-medium">Text</th>
                    <th width="130" class="font-medium pb-1" align="right">Belopp</th>
                </tr>
                <template v-for="trans in vouchersDataFiltrated">
                    <template v-for="transaction in trans.transactions">
                        <tr v-if="transaction.account === (selectedAccount.oldAccount || selectedAccount.account)" :key="transaction.id">
                            <td class="py-1">{{ formatDate(trans.bookingDate) }}</td>
                            <td class="py-1">{{ trans.serie }} {{ trans.number }}</td>
                            <td class="py-1">
                                {{ trans.text }} <span v-if="transaction.text"> <i class="fal fa-arrow-right mx-2" /> {{ transaction.text }}</span>
                            </td>
                            <td align="right">
                                {{ transaction.amount | swedishNumberFormat }}
                            </td>
                        </tr>
                    </template>
                </template>
            </table>
        </div>
        <div v-else class="flex flex-col items-center py-32">
            <img src="@/assets/images/no_data.svg" class="w-32" />
            <p class="mt-4">Ingen data har valts</p>
        </div>
    </div>
</template>
<script>
import moment from "moment";

export default {
    props: {
        selectedAccount: {
            type: Object,
            default: () => {},
        },
        vouchersData: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        vouchersDataFiltrated() {
            return this.vouchersData.filter(item =>
                item.transactions.some(it => {
                    return it.account === (this.selectedAccount.oldAccount || this.selectedAccount.account);
                }),
            );
        },
    },

    methods: {
        formatDate(date) {
            return moment(date).format("YYYY-MM-DD");
        },

        editItem() {
            this.$emit("editAccount");
        },

        extractText(item) {
            return item.transactions.filter(item => item.account === this.selectedAccount.account).map(it => it.text);
        },

        resetState() {
            if (!this.selectedAccount.oldAccount) return;
            this.$emit("reset", this.selectedAccount);
        },
    },
};
</script>
